import { Atom, SHARED_VALIDATIONS, helper } from ":mods";

export function createContactInputs() {
  const FullName = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.full_name.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });

  const Email = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.email.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Country = Atom.Form.createSelect("", {
    required: true,
  });
  const need_help_with = Atom.Form.createInput("", {
    required: true,
  });
  const Message = Atom.Form.createTextArea("", {
    required: true,
  });

  const ConfirmRead = Atom.Form.createInput(undefined, {
    required: true,
  });
  const ReceiveAlert = Atom.Form.createInput(undefined, {
    required: true,
  });

  const Actions = Atom.Form.createFormActions({
    FullName,
    Email,
    Country,
    need_help_with,
    Message,
    ConfirmRead,
    ReceiveAlert,
  });
  return {
    Actions,
    FullName,
    Email,
    Country,
    need_help_with,
    Message,
    ConfirmRead,
    ReceiveAlert,
  };
}
